import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import { Calendar } from '@fullcalendar/core';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { ConfirmationService, MessageService } from 'primeng/api';
import { events } from '../../domain/events';
import { User } from '../../domain/user';
import { eventservice} from '../../service/eventservice';
import { Observable, of } from 'rxjs';
import { scheduleeventservice } from '../../service/scheduleeventservice';
import { UserDetailsService } from '../../service/userDetails.service';
import { scheduleevent } from '../../domain/scheduleevent';
import { Visit } from '../../domain/visit';
import { VisitService } from '../../service/visitservice';
import { Validators,FormBuilder} from '@angular/forms';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { DateUtilService } from '../../service/date-util.service';
import { ServiceDetailsService } from '../../service/serviceDetails.service';
import { ServiceProviderService } from '../../service/serviceprovider.service';
import { serviceprovider } from '../../domain/serviceprovider';
//import { CalendarOptions } from '@fullcalendar/angular';


@Component({
  selector: 'app-usercalendar',
  templateUrl: './usercalendar.component.html',
  styleUrls: ['./usercalendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class UsercalendarComponent implements OnInit {
  startdate: Date;
  enddate: Date;
  starttime: Date;
  endtime: Date;
  events: any[];
  selectedUserId: any;
  selectedStatus:any=[];
  options: any; 
  hours: any[];
  days: any[];
  minutes: any[];
  visits: Visit[];
  loginuserId: any;
  loginusername : any;
  selectedEvent:scheduleevent;

  visitdatesModal = false;
  selecteduserdetails:User;
  scheduleevents: events[];
  scheduleeventstemp: events[];
  futureassignments: scheduleevent[];
  futureassignmentstemp: scheduleevent[];
  scheduleserviceeventtemp: scheduleevent[];
  isSubmitted :boolean;
  visitdatesisSubmitted = false;
  visitdisplayModal = false;
  sse :scheduleevent;
  selectedvisitevent:Visit;
  mindate: Date;
  maxdate: Date;
  currentPeriodmaxdate: Date;
  todaydate: Date=new Date();
  currentPeriod: any={};


  selectedVisit:scheduleevent;
  eventdisplayModal: boolean;
  description: string;
  minimumStartDate = new Date();
  
  minimumEndtDate = this.minimumStartDate;
  eventnoeditModal: boolean;
  selectedVisitdata: any;
  currentperiodminDate: Date;
  officerTwomsg: String;
  updateCalenderModal:boolean;
  value: number = 10;
  vacationCareOnly: boolean;
  largeproviderslist: serviceprovider[];
  constructor(private dateUtil:DateUtilService,private eventService: eventservice,public fb: FormBuilder,private servicedetails: ServiceDetailsService ,private serviceproviderservice: ServiceProviderService,
    private visitservice:VisitService,private UserDetailsService:UserDetailsService,private eventservice : eventservice,private messageService: MessageService,private scheduleeventservice:scheduleeventservice) { 
  
    this.hours = [
    {name: '0'},
    {name: '8'},
    {name: '9'},
    {name: '10'},
    {name: '11'},
    {name: '12'},
    {name: '13'},
    {name: '14'},
    {name: '15'},
    {name: '16'},
    {name: '17'},
    {name: '18'}    
  ];

  this.days = [
    {name: 'Mon', value: '1'},
    {name: 'Tue', value: '2'},
    {name: 'Wed', value: '3'},
    {name: 'Thu', value: '4'},
    {name: 'Fri', value: '5'},

  ];

  this.minutes = [
    {name: '00', code: '00'},
    {name: '15', code: '15'},
    {name: '30', code: '30'},
    {name: '45', code: '45'}
    
  ];
  }
  addeventform = this.fb.group({
    feventtype: ['', [Validators.required]],
    fstartdate: ['', [Validators.required]],
    fenddate: ['', [Validators.required]],
    fdaysofweek: ['', []],
    fnotes: ['', []],
    fhours: ['', []],
    fminutes: ['', []],
    fehours: ['', []],
    feminutes: ['', []],
  });

  updatevisitdatesform = this.fb.group({
    fvstartdate: ['', [Validators.required]],
    fvenddate: ['', [Validators.required]],
  });
  eventClick(model) {
    console.log(model);
  }


  dateLessThan(fstartdate: Date, fenddate: Date) {
   let startdate = new Date(fstartdate.getFullYear(), fstartdate.getMonth(), fstartdate.getDate(), 0, 0, 0);
  let enddate = new Date(fenddate.getFullYear(), fenddate.getMonth(), fenddate.getDate(), 0, 0, 0);
  if (enddate.getTime() < startdate.getTime()) {    
   return true;
  }   
  return false;  
 }
 minDateValue:Date;
 maxDateValue:Date;
 serviceslist:any;
 progressbar=true;
  ngOnInit() {
    this.progressbar=true;

    this.conflictmessage = "";

    this.minDateValue = new Date();
    this.minDateValue.setMonth( this.minDateValue.getMonth() - 6);

    this.maxDateValue=new Date();
    this.maxDateValue.setFullYear(this.maxDateValue.getFullYear() + 2)
    this.loginuserId =localStorage.getItem("loginuserid");
    this.loginusername =localStorage.getItem("username");

    this.selectedUserId =localStorage.getItem("USERID");
      
    this.UserDetailsService.getUserforId(this.selectedUserId).subscribe(userdata =>{   
      this.selecteduserdetails = userdata;
    });
    // this.getCurrentPeriod();

    this.serviceproviderservice.getAllSPDetails().subscribe(data=>{
      this.largeproviderslist = data.filter(el=>el.largeProviderName!=null);
     
    this.servicedetails.getAllServices().subscribe(sd=>{
       this.serviceslist = sd;

      console.log(this.serviceslist.length)
      let servicelist = this.serviceslist.filter(x => 
        this.largeproviderslist.some(y=> y.serviceProviderId == x.serviceProviderId)
      );
    
    // get future assigments 
    this.scheduleeventservice.getUserEventsByIdFirstOrSecond(this.selectedUserId).subscribe(ssedata =>{     
      if(ssedata!=null){
        this.futureassignmentstemp = ssedata;  
        this.futureassignments = [];
        this.futureassignmentstemp.forEach(eledata=>{
          if(eledata.eventType=='V'){
            eledata.eventType="AR"
          }
          let datafound = servicelist.filter(e=>e.serviceId===eledata.serviceId);

          //if its a large provider serviceId
          if(datafound && datafound.length>0)
          {
            eledata.markit=true;
            let serviceProviderName = this.largeproviderslist.find(p=>p.serviceProviderId==datafound[0].serviceProviderId);
            eledata.largeProviderName = serviceProviderName.largeProviderName;
          }
          if(eledata.futureVisit=='N' && eledata.startDate == null && eledata.archived==null){
            this.futureassignments.push(eledata)
          }
        });
        this.progressbar=false;

      }else{
        this.progressbar=false;

      }
    },err=>{
      this.progressbar=false;

    });
  })
});
    // get user events to populate o calendar 
    this.scheduleeventservice.getUserCalendarById(this.selectedUserId).subscribe(ssedata =>{
      if(ssedata!=null){
        this.scheduleserviceeventtemp = ssedata;  
        let d = new Date();
        d.setMonth(d.getMonth() - 6);
        

      //  console.log("this.userevents size before"+ this.scheduleserviceeventtemp.length + "date ="+d.getMonth())   
    
        this.scheduleserviceeventtemp = this.scheduleserviceeventtemp.filter(el=>((new Date(el.endDate)).getTime()>=d.getTime()))        
        this.events = [];
        this.scheduleserviceeventtemp.forEach(eledata=>{
          if(eledata.title=="O"){
            eledata.title="Others";
          }
          var bgcolor=this.getbgcolor(eledata.eventType);
        //  this.events.push({id:eledata.eventId,source:eledata,title:eledata.title,start:eledata.startDate,end:this.addDays(eledata.endDate),textColor:'black',editable: true,backgroundColor:bgcolor,allDay:true});  
      // var re = /00:00:00/;
        //var startdate = new Date(""+eledata.startDate);
        //var stDate = new Date(startdate.toString().replace(re, "00:00:01"));
       
        var enddate = new Date(""+eledata.endDate);
        var enddaterecur = new Date(""+eledata.endDateRecur);
       // enddate.setDate(enddate.getDate()+1);
      // var eDate = new Date(enddate.toString().replace(re, "00:00:00"));
      var eDate = new Date(enddate.getFullYear(), enddate.getMonth(), enddate.getDate()+1, 0, 0, 0);
      var eDateRecur =  new Date(enddaterecur.getFullYear(), enddaterecur.getMonth(), enddaterecur.getDate()+1, 0, 0, 0);
      var arr = null;

       //if its a second user - change color
       if(this.selecteduserdetails!=undefined && this.selecteduserdetails!=null && eledata.secondUserId=== this.selecteduserdetails.userId && eledata.eventType=='V')
       {
        bgcolor ='#c8f8c8'
       }


      if(eledata.daysofweek!=null)
      {
      arr = eledata.daysofweek.split(',');
      }
        this.events.push({id:eledata.eventId,source:eledata,title:eledata.title,start:eledata.startDate,end:eDate,textColor:'black',editable: false,backgroundColor:bgcolor,allDay:true,daysOfWeek:arr,startRecur:eledata.startDateRecur,endRecur:eDateRecur});    
      }); 
      }
    });
    this.options = {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        defaultDate: new Date,  
        header: {
            left: 'prev,next',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay',
        },
        editable: true,
        validRange: {
          start: this.minDateValue,
          end: this.maxDateValue,
        },
       

        eventClick: (event) => {
          this.vacationCareOnly=false;
          var publicHoliday= event.event.id.slice(0, 3);
          if(publicHoliday == "SPH" || publicHoliday == "STH"){
            return false;
          }
          console.log(event);
          
          this.scheduleeventservice.get(event.event.id).subscribe(sedata=>{
            this.selectedEvent = sedata;
            this.getPeriodByPerName(sedata.periodId);
            if (this.selectedEvent.eventType == "L" || this.selectedEvent.eventType == "F" || this.selectedEvent.eventType == "H" || this.selectedEvent.eventType == "O"){
              this.getleavedata(event.event.id);
            } else if (this.selectedEvent.eventType == "V" || this.selectedEvent.eventType == "COM" || this.selectedEvent.eventType == "CofO" || this.selectedEvent.eventType == "I") {
              this.getvisitdata(event.event.id);
            } else {
              // this.getnoedit();
              this.getleavedata(event.event.id);
            }
          });                   
        },
    };

    this.eventservice.getEvents().subscribe(eventdata =>{     
      if(eventdata!=null){
        this.scheduleeventstemp = eventdata;  
        this.scheduleevents = [];
        this.scheduleeventstemp.forEach(eledata=>{
          // show only Visit not =='Y' events 
          if(eledata.visit !='Y'){
            this.scheduleevents.push(eledata);
          }
        }); 
      }
    });
  }


  getyyddmm(d){
    return this.dateUtil.getCorrectedDefaultDate(d);
  }
  conflictmessage = "";
  checkConflictDatesforLP()
  {
   let fstartdate = this.updatevisitdatesform.get("fvstartdate").value
   let fenddate = this.updatevisitdatesform.get("fvenddate").value
   this.conflictmessage = "";

   console.log("periodName"+this.selectedVisitdata.periodName);

   if(fstartdate!=undefined && fstartdate!=undefined && fstartdate!="" && fenddate!=null && fenddate!=null && fenddate!="")
   {

   // let fstartdate =    new Date(startdate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"))
   fstartdate.setHours(0,0,0)


    fenddate.setHours(0,0,0)


    console.log(this.selectedVisitdata.serviceProvId)
    let conflictservicelist = this.serviceslist.filter(x => 
      x.serviceProviderId == this.selectedVisitdata.serviceProvId)
  
    console.log("conflictservicelist"+conflictservicelist.length)

    this.scheduleeventservice.getscheduleforperiodId(this.selectedVisitdata.periodName).subscribe(res=>{

      let filterdatascheduleevents= res.filter(x=>conflictservicelist.some(y=>y.serviceId==x.serviceId) && x.serviceId!=this.selectedVisitdata.serviceId && x.futureVisit=='N' && x.archived==null && x.startDate!=null && x.endDate!=null);
      console.log("filterdatascheduleevents"+filterdatascheduleevents.length)

      
      filterdatascheduleevents.forEach(element => {
     
        let sstartdate =    new Date(element.startDate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"))
        sstartdate.setHours(0,0,0)
        let senddate =    new Date(element.endDate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"))
        senddate.setHours(0,0,0)
        const datepipe: DatePipe = new DatePipe('en-AU')
        console.log(sstartdate  + "-"+ senddate)

        console.log(fstartdate +" - "+ fenddate)

        if ((sstartdate>=fstartdate && sstartdate<=fenddate) ||(senddate<=fenddate && senddate>=fstartdate)) {    
          this.conflictmessage= this.conflictmessage + element.serviceId +" between "+datepipe.transform(sstartdate, 'dd/MM/YYYY') + " - " +datepipe.transform(senddate, 'dd/MM/YYYY') + '\n'
        }  
        
      });
    

    })
   }
  }



  checkConflictDatesforLPfromcal()
  {
   let fstartdate = this.startdate
   let fenddate = this.enddate;
   this.conflictmessage = "";

   console.log("periodName"+this.selectedvisitevent.periodName);

   console.log("fstartdate"+fstartdate +  "   fenddate"+fenddate)

   if(fstartdate!=undefined && fstartdate!=undefined  && fenddate!=null && fenddate!=null )
   {

   // let fstartdate =    new Date(startdate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"))
   fstartdate.setHours(0,0,0)


    fenddate.setHours(0,0,0)


    console.log(this.selectedvisitevent.serviceProvId)
    let conflictservicelist = this.serviceslist.filter(x => 
      x.serviceProviderId == this.selectedvisitevent.serviceProvId)
  
    console.log("conflictservicelist"+conflictservicelist.length)

    this.scheduleeventservice.getscheduleforperiodId(this.selectedvisitevent.periodName).subscribe(res=>{

      let filterdatascheduleevents= res.filter(x=>conflictservicelist.some(y=>y.serviceId==x.serviceId) && x.serviceId!=this.selectedvisitevent.serviceId && x.futureVisit=='N' && x.archived==null && x.startDate!=null && x.endDate!=null);
      console.log("filterdatascheduleevents"+filterdatascheduleevents.length)

      
      filterdatascheduleevents.forEach(element => {
     
        let sstartdate =    new Date(element.startDate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"))
        sstartdate.setHours(0,0,0)
        let senddate =    new Date(element.endDate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"))
        senddate.setHours(0,0,0)
        const datepipe: DatePipe = new DatePipe('en-AU')
        console.log(sstartdate  + "-"+ senddate)

        console.log(fstartdate +" - "+ fenddate)

        if ((sstartdate>=fstartdate && sstartdate<=fenddate) ||(senddate<=fenddate && senddate>=fstartdate)) {    
          this.conflictmessage= this.conflictmessage + element.serviceId +" between "+datepipe.transform(sstartdate, 'dd/MM/YYYY') + " - " +datepipe.transform(senddate, 'dd/MM/YYYY') + '\n'
        }  
        
      });
    

    })
   }
  }


  editvisitdates(){
    this.messageService.clear();
    var re = /00:00:00/;
    var startdate = this.startdate
    var enddate = this.enddate

      
    if(startdate==null || enddate==null || startdate==undefined || enddate==undefined || startdate.toString()=='' || enddate.toString()=='')
    {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please enter Start Date and End Date' });
      return;
    }

    var stDate = new Date(startdate.toString().replace(re, "02:30:00"));
   
    var eDate = enddate.toString().replace(re, "23:59:00");
   
    if(this.dateLessThan(new Date(this.startdate),new Date(this.enddate))){
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'End date should be greater than Start date' });
      return;
    } else {
        this.scheduleeventservice.get(this.selectedvisitevent.eventId).subscribe(sedata=>{
          this.updateCalenderModal=true;
          this.sse = sedata; 
          this.sse.startDate = new Date(stDate);
          this.sse.endDate = new Date(eDate);
          this.sse.updatedBy = this.loginusername;
          this.sse.updatedDate = new Date();
          
          const data={
            startDate :environment.datePipe.transform(new Date(stDate), 'yyyy-MM-dd'),
            endDate : environment.datePipe.transform(new Date(eDate), 'yyyy-MM-dd') ,
            updatedBy : this.loginusername,
            updatedDate : new Date(),
          }
            this.scheduleeventservice.updateserviceeventswithvdate(this.selectedvisitevent.eventId,data).subscribe(res=>{
              this.visitdisplayModal = false;
              this.updateCalenderModal=false;
              this.messageService.add({ severity: 'success', summary: 'success', detail: 'Visit dates updated for '+this.selectedvisitevent.serviceId});
              this.ngOnInit();
            },err=>{
              this.visitdisplayModal = false;
              this.updateCalenderModal=false;
              this.messageService.add({ severity: 'error', summary: 'success', detail: 'Visit dates updation failed for '+this.selectedvisitevent.serviceId});
            });
            
        });
    }
  }

  getPeriodByPerName(id:any) {
    this.currentPeriod = null;
      this.currentperiodminDate=null;
      this.currentPeriodmaxdate=null;
    this.visitservice.getPeriodsById(id).subscribe(data=>{
      console.log(data);
      
      this.currentPeriod = data;
      this.currentperiodminDate=new Date(this.currentPeriod.perStartDate);
      this.currentPeriodmaxdate=new Date(this.currentPeriod.perEndDate);
      
    })
 }

//   getCurrentPeriod() {
//     this.scheduleeventservice.getCurrentPeriod().subscribe(data=>{
//       this.currentPeriod = data;
//       this.currentperiodminDate=new Date(this.currentPeriod.perStartDate);
//       this.currentPeriodmaxdate=new Date(this.currentPeriod.perEndDate);
      
//     })
//  }

  getnoedit(){
    this.eventnoeditModal = true; 
    // this.visitservice.getEventsById(eventId).subscribe(sedata=>{
    //   console.log(sedata);
    //   this.selectedvisitevent = sedata;
    //   this.startdate = new Date(sedata.startDate);
    //   this.enddate = new Date(sedata.endDate);
    //   this.mindate = new Date(sedata.periodStartDate);
    //   this.maxdate = new Date(sedata.periodEndDate);
      
    // });
  }

  getvisitdata(event:String){    
    this.messageService.clear();
    //get the event data and type
    this.conflictmessage="";
    this.visitdisplayModal = true;   
    this.visitservice.getEventsById(event).subscribe(sedata=>{
      console.log(sedata);
      this.selectedvisitevent = sedata;
      if(!this.selectedvisitevent.isLongDayCare && !this.selectedvisitevent.outHoursCareAfter && !this.selectedvisitevent.outHoursCareBefore && this.selectedvisitevent.outHoursVacationCare){
        this.vacationCareOnly=true;
      }
      this.startdate = new Date(sedata.startDate);
      this.enddate = new Date(this.getyyddmm(sedata.endDate));
      this.mindate = new Date(sedata.periodStartDate);
      this.maxdate = new Date(sedata.periodEndDate);
      
    });
  }
  
  addDays(date: Date): Date {
    let yourDate = new Date(date);
    yourDate.setDate(yourDate.getDate());
    return yourDate;
  }

  isSecondOfficer(secondUserId: string)
  {
    if(this.selecteduserdetails!=undefined && this.selecteduserdetails!=null && secondUserId=== this.selecteduserdetails.userId)
    {

      return true;
    }
    else{
      return false;
    }
  }

  updateVisitDatesDialog (scheduleevent:scheduleevent){
    console.log(scheduleevent);
    this.getPeriodByPerName(scheduleevent.periodId);
    this.vacationCareOnly=false;
    this.visitdatesModal = true;
    this.conflictmessage = "";
    this.updatevisitdatesform = this.fb.group({
      fvstartdate: ['', [Validators.required]],
      fvenddate: ['', [Validators.required]],
    });
   // alert(scheduleevent.eventId)
    this.visitservice.getEventsById(scheduleevent.eventId).subscribe(sedata=>{
      this.selectedVisitdata = sedata;
      if(!sedata.isLongDayCare && !sedata.outHoursCareAfter && !sedata.outHoursCareBefore && sedata.outHoursVacationCare){
        this.vacationCareOnly=true;
      }
      this.startdate = new Date(sedata.startDate);
      this.enddate = new Date(sedata.endDate);
      this.mindate = new Date(sedata.periodStartDate);
      this.maxdate = new Date(sedata.periodEndDate);
    });    
  }
  resetenddate()
  {
    this.addeventform.get("fenddate").setValue('');
  }
  updatevisitreset()
  {
    this.updatevisitdatesform.get("fvenddate").setValue('');
  }
  updatevisitdates() {
    var re = /00:00:00/;
    var startdate = this.updatevisitdatesform.get("fvstartdate").value; 
    var enddate = this.updatevisitdatesform.get("fvenddate").value;
    if(startdate==undefined || startdate==null || startdate.toString()=='' || enddate==undefined || enddate==null || enddate.toString()=='')
    {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'End date and Start date are required' });
      this.updateCalenderModal = false;
      this.visitdatesisSubmitted=true;
      return false;
    }

    var stDate = startdate.toString().replace(re, "2:30:01");
    var eDate = enddate.toString().replace(re, "23:59:00");     
      if(this.dateLessThan(new Date(stDate),new Date(eDate))){
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'End date should be greater than Start date' });
        this.updateCalenderModal = false;
        return false;
      }
     this.updateCalenderModal=true;
    this.visitdatesisSubmitted = true;  
          const data={
            startDate : environment.datePipe.transform(new Date(stDate), 'yyyy-MM-dd'),
            endDate : environment.datePipe.transform(new Date(eDate), 'yyyy-MM-dd'), 
            updatedBy : this.loginusername,
            updatedDate : new Date(),
          } 
          this.scheduleeventservice.updateserviceeventswithvdate(this.selectedVisitdata.eventId,data).subscribe(res=>{
            this.updateCalenderModal = false;
            this.visitdatesisSubmitted = false;
            this.visitdatesModal = false;
            this.messageService.add({ severity: 'success', summary: 'success', detail: 'Updated visit dates for '+this.selectedVisitdata.serviceId});
            console.log("--update success--");      
            this.ngOnInit();
            this.updatevisitdatesform = this.fb.group({
              fvstartdate: ['', [Validators.required]],
              fvenddate: ['', [Validators.required]],
            });
          
          

          },err=>{
            this.updateCalenderModal = false;
            this.visitdatesisSubmitted = false;
            this.visitdatesModal = false;
            this.messageService.add({ severity: 'error', summary: 'error', detail: 'Updated visit dates for '+this.selectedVisitdata.serviceId});
            console.log("-- updates failed --");
            this.updatevisitdatesform = this.fb.group({
              fvstartdate: ['', [Validators.required]],
              fvenddate: ['', [Validators.required]],
            });
          });         
    }
  
    onSubmit() {
      let flag= true;

      if(this.addeventform.get("fstartdate").value==undefined || this.addeventform.get("fstartdate").value==null 
      || this.addeventform.get("fenddate").value==undefined || this.addeventform.get("fenddate").value==null 
        || this.addeventform.get("feventtype").value ==undefined || this.addeventform.get("feventtype").value ==null 
        || ((this.addeventform.get("feventtype").value=='MAT' || this.addeventform.get("feventtype").value=='O' || this.addeventform.get("feventtype").value=='LP') && (this.addeventform.get("fdaysofweek").value ==undefined || this.addeventform.get("fdaysofweek").value ==null))
      )
      {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'All fields are required' });
        return;
      }  
      var re = /00:00:00/; 

      var startdate = this.addeventform.get("fstartdate").value;
        var stDate = startdate.toString().replace(re, "0:00:01");
       
        var enddate = this.addeventform.get("fenddate").value;
        var eDate = enddate.toString().replace(re, "23:59:00");
        
        if(this.dateLessThan(new Date(stDate),new Date(eDate))){
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'End date should be greater than Start date' });
          return false;
        }

      this.isSubmitted = true;
      if (!this.addeventform.valid) {
        console.log(this.addeventform.valid);
        
        return false;
      }
      else {
        //check for clashing events
        this.scheduleeventservice.getUserEventsById(this.selectedUserId).subscribe(res=>{
         
          let mypromise = new Promise((resolve,reject) => {

            if(res==null || res==undefined || res.length==0)
            {
              resolve(null);
  
            }

            res.forEach((element,index)=>{

           
          let startDate = environment.datePipe.transform(new Date(this.addeventform.get("fstartdate").value), 'yyyy-MM-dd'); 
          let endDate =environment.datePipe.transform(new Date(this.addeventform.get("fenddate").value), 'yyyy-MM-dd');

          if(element.eventType=='MAT' || element.eventType=='O' || element.eventType=='LP')
          {
            if((startDate>=element.startDateRecur && startDate<=element.endDateRecur) || (endDate>=element.startDateRecur && endDate<=element.endDateRecur) || (startDate<element.startDateRecur && endDate>element.endDateRecur) )
           {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Clashing event' });
           
               
            resolve(null);
          

            flag= false;
           }

          }


          else if((startDate>=element.startDate && startDate<=element.endDate) || (endDate>=element.startDate && endDate<=element.endDate) || (startDate<element.startDate && endDate>element.endDate) )
          {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Clashing event' });
           
               
            resolve(null);
          

            flag= false;

          }

          if(index === res.length -1 )
          {

              resolve(null);
          }

        })
        
        })
        mypromise.then(() => {
          if(flag)
          {
        this.sse = new scheduleevent;
        this.sse.userId = this.selectedUserId;
        if(this.addeventform.get("feventtype").value =='L'){
          this.sse.title = 'Leave';
        } else if (this.addeventform.get("feventtype").value=='F') {
          this.sse.title = 'Flex';
        } else if (this.addeventform.get("feventtype").value=='H') {
          this.sse.title = 'Travel';
        } else if (this.addeventform.get("feventtype").value=='CofO'){
          this.sse.title = 'Change of Ownership';
        }else if (this.addeventform.get("feventtype").value=='SV'){
          this.sse.title = 'Secondment Visit';
        }else if (this.addeventform.get("feventtype").value=='I'){
          this.sse.title = 'Investigation';
        }else if (this.addeventform.get("feventtype").value=='COM'){
          this.sse.title = 'Compliance';
        }else if (this.addeventform.get("feventtype").value=='ME'){
          this.sse.title = 'Multiple Events';
        }else if (this.addeventform.get("feventtype").value=='V'){
          this.sse.title = 'AR';
        }else if (this.addeventform.get("feventtype").value=='O'){
          this.sse.title = 'Others';
        }else if (this.addeventform.get("feventtype").value=='R'){
          this.sse.title = 'Report Writing';
        }else if (this.addeventform.get("feventtype").value=='T'){
          this.sse.title = 'Training';
        }else if (this.addeventform.get("feventtype").value=='M'){
          this.sse.title = 'Team Meeting';
        }else if (this.addeventform.get("feventtype").value=='CF'){
          this.sse.title = 'Conference';
        }else if (this.addeventform.get("feventtype").value=='D'){
          this.sse.title = 'Desktop Audit';
        }else if (this.addeventform.get("feventtype").value=='A'){
          this.sse.title = 'Admin Day';
        }else if (this.addeventform.get("feventtype").value=='PR'){
          this.sse.title = 'Pre Approval';
        }else if (this.addeventform.get("feventtype").value=='PA'){
          this.sse.title = 'Post Approval';
        }else if (this.addeventform.get("feventtype").value=='MAT'){
          this.sse.title = 'Mat Leave';
        }else if (this.addeventform.get("feventtype").value=='LP'){
          this.sse.title = 'Leave - Part-time';
        }
        this.sse.eventType = this.addeventform.get("feventtype").value;
            
        
        this.sse.startDate = environment.datePipe.transform(new Date(this.addeventform.get("fstartdate").value), 'yyyy-MM-dd'); 
        this.sse.endDate =environment.datePipe.transform(new Date(this.addeventform.get("fenddate").value), 'yyyy-MM-dd');
      
        
        this.sse.createdBy = this.loginusername;
        this.sse.createdDate = new Date();
        this.sse.description = this.addeventform.get("fnotes").value;

        if(this.addeventform.get("feventtype").value=='MAT' || this.addeventform.get("feventtype").value=='O' || this.addeventform.get("feventtype").value=='LP')
        {
          var values = Array.from(this.addeventform.get('fdaysofweek').value).map(({ value }) => value);
          console.log(values);
          this.sse.startDateRecur = environment.datePipe.transform(new Date(this.addeventform.get("fstartdate").value), 'yyyy-MM-dd'); 
          this.sse.endDateRecur =environment.datePipe.transform(new Date(this.addeventform.get("fenddate").value), 'yyyy-MM-dd');
          this.sse.daysofweek= values.toString(); 
        }
        // call service
        this.scheduleeventservice.create(this.sse).subscribe(res => {
          this.isSubmitted = false;
          this.addeventform.reset();
          this.addeventform.patchValue({
            feventtype: "L",
          });
          this.messageService.add({ severity: 'success', summary: 'success', detail: 'Event added' });
          // console.log("created !!!!!!!!!!!!!");
          this.ngOnInit();
        }, err => {
          this.messageService.add({ severity: 'error', summary: 'failed', detail: 'Event failed' });
          console.log("failed !!!!!!!!!!!!!");
        });
      }
      })
     
        
      })
      }
      
    }

  
  
  getbgcolor(eventType: any) {
    /*
      leave and Flex #F03030
      Training and Conference - #3361FF
      Team meeting and Admin Day - #A569BD
      Report Writing and Desktop Audit - #ABEBC6
      Compliance and Investigation - #FCE32E
      Pre,Post approval and change of ownership - #C10AC3
      Travel - #F06630 
      Assessment and visit -  #1AA11A
      Secondment Visit - #EBEEEE
      Multiple -#9B9E9C
      others - #26C8F3
      School holidays watermark colorMultiple events - #D6F9F1
    */
    var bgcolor = '';
    if (eventType == 'L' || eventType == 'F') {
      bgcolor = '#F03030';
    } else if (eventType == 'T' || eventType == 'CF') {
      bgcolor = '#6699CC';
    } else if (eventType == 'M' || eventType == 'A') {
      bgcolor = '#A569BD';
    } else if (eventType == 'D' || eventType == 'R') {
      bgcolor = '#ABEBC6';
    } else if (eventType == 'COM' || eventType == 'I' || eventType == 'MAT') {
      bgcolor = '#FCE32E';
    } else if (eventType == 'PA' || eventType == 'PR' || eventType == 'CofO') {
      bgcolor = '#C10AC3';
    } else if (eventType == 'H') {
      bgcolor = '#F06630 ';
    } else if (eventType == 'V') {
      bgcolor = '#1AA11A';
    } else if (eventType == 'ME') {
      bgcolor = '#9B9E9C';
    } else if (eventType == 'O') {
      bgcolor = '#26C8F3';
    } else if (eventType == 'SV') {
      bgcolor = '#EBEEEE';
    } else if (eventType == 'W') {
      bgcolor = '#F7F9FB';
    } else if(eventType == 'termHoliday'){
      bgcolor = '#D3D3D3';
    }else if(eventType == 'publicHoliday'){
      bgcolor = '#ffcccb';
    }else if(eventType == 'LP'){  
      bgcolor = '#F4C2C2';
    }else{
      bgcolor='';
    }

    return bgcolor;
  }


  get feventtype() {
    return this.addeventform.get('feventtype');
  }
  get fdaysofweek() {
    return this.addeventform.get('fdaysofweek');
  }
  get fstartdate() {
    return this.addeventform.get('fstartdate');
  }
  get fenddate() {
    return this.addeventform.get('fenddate');
  }
  get fvstartdate() {
    return this.updatevisitdatesform.get('fvstartdate');
  }
  get fvenddate() {
    return this.updatevisitdatesform.get('fvenddate');
  }  


  


  editeventdates(){
    let flag = true;
    this.messageService.clear();
    console.log(this.startdate);
    console.log(this.enddate);
    console.log("update events");
    var re = /00:00:00/;          

    if(this.dateLessThan(new Date(this.startdate),new Date(this.enddate))){
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'End date should be greater than Start date' });
      return;
    } else {

      //check for clashing events
      console.log("check for clashing events");

      this.scheduleeventservice.getUserEventsById(this.selectedUserId).subscribe(res=>{

        let mypromise = new Promise((resolve,reject) => {

          if(res==null || res==undefined || res.length==0)
          {
            resolve(null);

          }
       
          res.forEach((element,index)=>{

         
            let startDate = environment.datePipe.transform(new Date(this.startdate), 'yyyy-MM-dd');
            let endDate = environment.datePipe.transform(new Date(this.enddate), 'yyyy-MM-dd');
        if(element.eventType=='MAT' || element.eventType=='O' || element.eventType=='LP')
        {
          if(((startDate>=element.startDateRecur && startDate<=element.endDateRecur) || (endDate>=element.startDateRecur && endDate<=element.endDateRecur) || (startDate<element.startDateRecur && endDate>element.endDateRecur)) && (this.selectedEvent.eventId!=element.eventId))
         {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Clashing event' });
         
             
          resolve(null);
        

          flag= false;
         }

        }


        else if(((startDate>=element.startDate && startDate<=element.endDate) || (endDate>=element.startDate && endDate<=element.endDate) || (startDate<element.startDate && endDate>element.endDate)) &&  (this.selectedEvent.eventId!=element.eventId))
        {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Clashing event' });
         
             
          resolve(null);
        

          flag= false;

        }

        if(index === res.length -1 )
        {

            resolve(null);
        }

      })
      
      })
      mypromise.then(() => {
        if(flag)
        {


      this.scheduleeventservice.get(this.selectedEvent.eventId).subscribe(sedata=>{
        this.sse = sedata; 
        var startdate = this.startdate
        var stDate = startdate.toString().replace(re,"12:00:00");
        console.log(startdate);
        var enddate = this.enddate
        var eDate = enddate.toString().replace(re, "23:59:00");
        var description=this.description;
        
        this.sse.startDate = environment.datePipe.transform(new Date(startdate), 'yyyy-MM-dd');
        this.sse.endDate = environment.datePipe.transform(new Date(eDate), 'yyyy-MM-dd');
        this.sse.description = description;
        
        this.sse.updatedBy = this.loginusername;
        this.sse.updatedDate = new Date();
        if(this.sse.eventType=='MAT' || this.sse.eventType=='O' || this.sse.eventType=='LP')
        {
         
          this.sse.startDateRecur = this.sse.startDate;
          this.sse.endDateRecur =this.sse.endDate;
        }
          this.scheduleeventservice.updateEventsOnSchedule(this.sse).subscribe(res=>{
            this.messageService.add({ severity: 'success', summary: 'success', detail: 'Event dates updated.'});
            console.log("--update success--");      
            this.ngOnInit();
          },err=>{
            this.messageService.add({ severity: 'error', summary: 'success', detail: 'Event dates updation failed.'});
            console.log("-- updates failed --");
          });
          this.eventdisplayModal = false;
        
      
      });   
    }
  }) 
    })
  }
  
  }

  getleavedata(event:String){
    this.messageService.clear();
    //get the event data and type
    this.eventdisplayModal = true;   
    this.scheduleeventservice.get(event).subscribe(sedata=>{
      this.selectedEvent = sedata;
      this.startdate = new Date(sedata.startDate);
      this.enddate = new Date(this.getyyddmm(sedata.endDate));
      this.description = this.selectedEvent.description;
      console.log(this.selectedEvent.eventId);
    });
  }

  removeevent(){
    this.messageService.clear();  
    console.log('remove event');
    this.scheduleeventservice.delete(this.selectedEvent.eventId).subscribe(res=>{
    this.messageService.add({ severity: 'success', summary: 'success', detail: 'Event removed.'});
    console.log("--update success--");      
    this.ngOnInit();
        },err=>{
        this.messageService.add({ severity: 'error', summary: 'fail', detail: 'Event remove failed.'});
        console.log("-- updates failed --");
        });
        this.eventdisplayModal = false;    
  }
  
  
}
